import React , { useState } from "react";
import style from './Diet-table-fc.module.scss';
import MainTable from "../table";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Modal from "../modal";
import '../../styles/global.css';
import DietAddForm from "../diet-add-form";
import { http } from "../../utilits/http";
import ClickAwayListener from "react-click-away-listener";
import Loading from "../loading";
import {toast} from 'react-toastify';

const DietTableFc = ( {dietAddData , onUpdate } ) => {
    const [showMenu, setShowMenu] = useState(-1);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const[isLoading , setIsLoading] = useState(false);

    const dietFields = [ 'sl.no' , 'diet name' , 'dietician name' , 'duration' , 'price' , 'status', 'action' ];
    
    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };
    const handleUpdateClick = (data) => {
        setItemToUpdate(data);
        setUpdateModalOpen(true);
    };
    const confirmUpdate = (updatedData) => {
        onUpdate(updatedData);
        setUpdateModalOpen(false);
        setItemToUpdate(null);
    };
    console.log('data:' , dietAddData)

    const handleAcceptRequest = async (id) => {
        setIsLoading(true);
        try {
            const response = await http.patch(`activate-diet-plan/${id}/`, {
              is_active: true,
            });
            if(response){
              onUpdate()
              setShowMenu(false)
            }
            setIsLoading(false);
            toast.success("Status changed successfully")
        } catch (error) {
            console.error("Error accepting request:", error);
            setIsLoading(false);
            toast.error("failed to update status");
        }
      };
    const handleRejectRequest = async (id) => {
      setIsLoading(true);
        try {
            const response = await http.patch(`activate-diet-plan/${id}/`, {
              is_active: false,
            });
            if(response){
              onUpdate()
              setShowMenu(false);
            }
            setIsLoading(false);
            toast.success("Status changed successfully");
        } catch (error) {
            console.error("Error rejecting request:", error);
            setIsLoading(false);
            toast.error("failed to update status")
        }
    };
    
    return(
        <div className={style.diet_list_table_container}>
            <MainTable headers={dietFields}>
                {dietAddData?.map(( data,index ) => {
                    const status = data.is_active ? "active" : "inactive";
                    return(
                        <tr className="table_body" key={index}>
                            <td className="table_body_data">{index+1}</td>
                            <td className="table_body_data"><p>{data.name}</p></td>
                            <td className="table_body_data"><p>{data.dietitian_name}</p></td>
                            <td className="table_body_data">{data.duration}{data.dd_mm}</td>
                            <td className="table_body_data">{data.price}</td>
                            <td className={`table_body_data ${data.is_active ? 'active-status' : 'pending-status'}`}>{status}</td>
                            <td className="table_body_action">
                            {isLoading && <Loading />}
                            <div className="menu_dropdown">
                                <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                                {showMenu === index && (
                                  <ClickAwayListener onClickAway={()=>setShowMenu(false)}>
                                      <div className="dropdownContent">
                                          <div>
                                              <button className="action_button" onClick={() => handleUpdateClick(data)}>Edit</button>
                                          </div>
                                          <div>
                                              <button className="action_button" onClick={() => handleAcceptRequest(data.id)}>Activate</button>
                                          </div>
                                          <div>
                                              <button className="action_button" onClick={() => handleRejectRequest(data.id)}>Deactivate</button>
                                          </div>
                                      </div>
                                    </ClickAwayListener>
                                )}
                            </div>
                        </td>
                        </tr>
                    )
                })}
            </MainTable>
            {updateModalOpen && (
                <Modal onClose={() => setUpdateModalOpen(false)} showClose>
                    <DietAddForm
                        initialFormValues={itemToUpdate}
                        onUpdate={confirmUpdate}
                        onClose={()=>{setUpdateModalOpen(false);setShowMenu(false)}}
                        isEdit
                    />
                </Modal>
            )}
        </div>
    )
}
export default DietTableFc;
