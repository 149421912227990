import React , {useState} from "react";
import style from './Health-condition-form.module.scss';
import { Formik , ErrorMessage } from "formik";
import * as Yup from 'yup';
import InputField from "../input-field";
import '../../styles/global.css';
import Loading from "../loading";
import { http } from "../../utilits/http";
import { toast } from 'react-toastify';

const HealthForm = ({ initialFormValues , onUpdate , isEdit , closeModal}) => {
    const formModeClass = isEdit ? 'edit-mode' : 'add-mode';
    const formModeHeading = isEdit ? 'Edit health condition' : 'Add health condition';
    const [loading, setLoading] = useState(false);
    const [showError , setShowError] = useState("");

    const onSubmitHandler = async (values, { resetForm }) => {
        setShowError("")
        setLoading(true);
        values.name=values.name.toLowerCase();
        console.log(values.name)
        if (isEdit) {  
            http.patch(`health-condition-admin/${initialFormValues.id}/`, values)
                .then((res) => {
                    onUpdate();
                    resetForm('data:', values);
                    closeModal()
                    setLoading(false);
                    toast.success('Health condition edited successfully');
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    if (err.response) {
                        toast.error(err.response.data.message || 'Failed to add health condition');
                        setShowError(err.response.data.message);
                    } else {
                        toast.error('Failed to add health condition');
                        setShowError("An error occurred while sending the request");
                    }
                });
        } else {
            http.post('health-condition-admin/', values) 
            .then((res) => {
                onUpdate();
                resetForm('data:', values);
                console.log('data', res);
                toast.success('Health condition created successfully');
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                // toast('failed to add health condition');
                // setShowError(err?.response?.data);
                if (err.response) {
                    toast.error(err.response.data.message || 'Failed to add health condition');
                    setShowError(err.response.data.message);
                } else {
                    toast.error('Failed to add health condition');
                    setShowError("An error occurred while sending the request");
                }
            });
        }
    };

    const INITIALVALUES = {
        name: initialFormValues ? initialFormValues.name : "",
    };

    const handleClear = (resetForm) => {
        resetForm(initialFormValues)
    }
    return(
        <div className={`form_container ${formModeClass}`}>
            {loading && <Loading />}
            <div className="form_head">
                {formModeHeading}
            </div>
            <hr className="seperator"/>
            <Formik 
                initialValues={INITIALVALUES}
                validationSchema={Yup.object().shape({
                    // name:Yup.string().required("Name is required").matches(/^[A-Za-z\s]+$/, 'Enter a valid name').max(30, 'Health condition name must be at most 30 characters')
                    name: Yup.string()
                        .required("Name is required")
                        .test('No-empty-spaces', 'Enter a valid name', (value) => {
                        return value.trim() !== '';
                    })
                    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
                    .max(20, 'Name must be at most 20 characters'),
                })}
                onSubmit={onSubmitHandler}
            >
                {({ values , handleSubmit , handleBlur , handleChange , resetForm }) => (
                    <form onSubmit={handleSubmit} className="form_wrapper">
                        <div className={style.form_field}>
                            <InputField 
                                type="text"
                                name="name"
                                value={values.name}
                                label="name"
                                placeholder="Name of the health condition"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                width="small"
                                isRequired={true}
                            />
                        </div>
                        <div className="error_msg">
                            <ErrorMessage name="name" />
                        </div>
                        {/* <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div> */}
                        <div className="button_wrapper">
                            {!initialFormValues && ( 
                                <button className="input_clear_btn" type="button" onClick={() => handleClear(resetForm)}>
                                    Clear
                                </button>
                            )}
                            <button className="input_add_btn" type="submit">
                                {initialFormValues ? "Update" : "Add"}
                            </button>
                            {initialFormValues && ( 
                                <button className="input_cancel_btn" type="button" onClick={closeModal}>
                                    Cancel
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        
        </div>
    )
}
export default HealthForm;