import React, { useState, useEffect } from "react";
import style from './Diet-list.module.scss';
import Container from "../container";
import MainTable from "../table";
import Heading from "../heading";
import { http } from '../../utilits/http.js';
import '../../styles/global.css';
import Pagination from "../pagination";
import Search from "../search";
import { useLocation, useNavigate } from "react-router-dom";


const DietList = () => {
    const [data, setData] = useState({ results: [], count: 0 });
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const dietFields = ['sl.no', 'diet name', 'dietician name', 'duration', 'price', 'status'];

    const fetchData = async () => {
        try {
            const response = await http.get(`diet-plan/?search=${searchTerm}&page=${currentPage + 1}`);
            setData(response.data);
            console.log(response.data, 'res')
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [currentPage, searchTerm]);

    const pageCount = Math.ceil(data.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = data.results.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    return (
        <div className={style.diet_page}>
            <div className={style.search_bar}>
                <Search onSearch={handleSearch} />
            </div>
            <div className={style.diet_head}><Heading name="Diet Plans" /></div>
            <div className={style.diet_list}>
                <MainTable headers={dietFields}>
                    {filteredData.map((diet, index) => {
                        const status = diet.is_active ? "active" : "inactive";
                        return (
                            <tr className="table_body" key={index}>
                                <td className="table_body_data">{index + 1}</td>
                                <td className="table_body_data"><p>{diet.name}</p></td>
                                <td className="table_body_data"><p>{diet.dietitian_name}</p></td>
                                <td className="table_body_data">{diet.duration}</td>
                                <td className="table_body_data">{diet.price}</td>
                                <td className={`table_body_data ${diet.is_active ? 'active-status' : 'pending-status'}`}>{status}</td>
                            </tr>
                        )
                    })}
                </MainTable>
            </div>
            {pageCount > 1 && (
                <div>
                    <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                </div>
            )}
        </div>
    )
}

export default DietList;
