import React , {useState} from "react";
import style from './Allergy-form.module.scss';
import { Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import InputField from "../input-field";
import Loading from "../loading";
import { http } from "../../utilits/http";
import {toast} from 'react-toastify';

const AllergyForm = ({ initialFormValues , onUpdate , isEdit , closeModal , onClose }) => {
    const formModeClass = isEdit ? 'edit-mode' : 'add-mode';
    const formModeHeading = isEdit ? 'Edit allergy' : 'Add allergies';
    const [loading, setLoading] = useState(false);
    const [showError , setShowError] = useState("");

    const onSubmitHandler = async (values, { resetForm }) => {
        setShowError("");
        setLoading(true);
        values.name = values.name.toLowerCase();
        console.log("name",values.name)
            if (isEdit) {  
                http.patch(`allergy-admin/${initialFormValues.id}/`, values)
                    .then((res) => {
                        onUpdate();
                        resetForm('data:', values);
                        closeModal()
                        setLoading(false);
                        toast.success("Allergy edited successfully")
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                        if (err.response) {
                            toast.error(err.response.data.message || 'Failed to add allergy');
                            setShowError(err.response.data.message);
                        } else {
                            toast.error('Failed to add allergy');
                            setShowError("An error occurred while sending the request");
                        }
                    });
            } else {
                http.post('allergy-admin/', values) 
                .then((res) => {
                    onUpdate();
                    resetForm('data:', values);
                    console.log('data', res);
                    toast.success("Allergy created successfully")
                    setLoading(false)
                })
                .catch((err) => {
                    // console.log(err);
                    setLoading(false);
                    if (err.response) {
                        toast.error(err.response.data.message || 'Failed to add allergy');
                        setShowError(err.response.data.message);
                    } else {
                        toast.error('Failed to add allergy');
                        setShowError("An error occurred while sending the request");
                    }
                });
            }
    };

    const handleClearClick = (resetForm) => {
        resetForm(initialFormValues)
    };

    const INITAILVALUES = {
        name: ""
    };

    return(
        <div className={`form_container ${formModeClass}`}>
            {loading && <Loading />}
            <div className="form_head">
                {formModeHeading}
            </div>
            <hr className="seperator"/>
            <Formik  
                initialValues={initialFormValues ? initialFormValues : INITAILVALUES}
                validationSchema={Yup.object().shape({
                    // name:Yup.string().required("Allery name is required").matches(/^[A-Za-z\s]+$/, 'Enter valid allergy name').max(15, 'Allergy name must be at most 15 characters')
                    name: Yup.string()
                        .required("Allery name is required")
                        .test('No-empty-spaces', 'Enter a valid allergy name', (value) => {
                        return value.trim() !== '';
                        })
                        .matches(/^[A-Za-z\s]+$/, 'Enter a valid allergy name')
                        .max(20, 'Allergy name must be at most 20 characters'),
                })}
                onSubmit={onSubmitHandler}
            >
            {({ values , handleChange , handleBlur , handleSubmit , resetForm })=>(
               <form onSubmit={handleSubmit} className="form_wrapper">
                    <div className={style.form_field}>
                        <InputField 
                            type="text"
                            name="name"
                            value={values.name}
                            label="Allergies name"
                            placeholder="name of the allergy"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            width="large"
                            isRequired={true}
                        />
                    </div>
                    <div className="error_msg">
                        <ErrorMessage name="name" />
                    </div>
                    <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div>
                    <div className="button_wrapper">
                        <button
                            className="input_cancel_btn"
                            type="button"
                            onClick={() =>
                            initialFormValues ? closeModal() : handleClearClick(resetForm)
                            }
                        >
                            {initialFormValues ? "Cancel" : "Clear"}
                        </button>

                        <button className="input_add_btn" type="submit">
                            {initialFormValues ? "Update" : "Add"}
                        </button>
                    </div>
                </form>
            )}
            </Formik>
        </div>
    )
}
export default AllergyForm;