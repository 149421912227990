import React , {useState , useEffect} from "react";
import style from './Health-condition-page.module.scss';
import Container from "../container";
import HealthForm from "../health-condition-form";
import HealthTable from "../health-condition-table";
import Heading from "../heading";
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import Search from "../search";

const HealthConditionPage = () => {
    const [healthData, setHealthData] = useState({ results: [], count: 0 });
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState("");


        const fetchData = async () => {
            try {
                const response = await http.get(`health-condition-admin/?search=${searchTerm}&page=${currentPage + 1}`);
                setHealthData(response.data); 
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };    

    useEffect(() => {
        fetchData();
    }, [searchTerm,currentPage]); 

    const pageCount = Math.ceil(healthData.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = healthData?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };
    
    const handleDelete = async (id) => {
        setLoading(true)
        try {
            await http.delete(`health-condition-admin/${id}/`);
            fetchData();
            setLoading(false)
            toast.success("Health condition deleted successfully")
        } catch (error) {
            console.error("Error deleting unit:", error);
            setLoading(false)
            toast.error("Failed to delete health condition");
        }
    };

    return(
        <Container>
            <div className={style.health_container}>
                {loading && <Loading />}
                <div className={style.health_page_head}>
                    <Heading name="Health condition"/>
                </div>
                <div className="row">
                    <div className="col-5">
                        <HealthForm onUpdate={fetchData}/>
                    </div>
                    <div className="col-7">
                        <HealthTable healthData={filteredData} onDelete={handleDelete} onUpdate={fetchData} />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default HealthConditionPage;