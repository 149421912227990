import React , { useState , useEffect} from "react";
import style from './Diet-preference-page.module.scss';
import Container from "../container";
import DietPrefernceForm from "../diet-preference-form";
import DietTable from "../diet-preference-table";
import Heading from "../heading";
import { http } from "../../utilits/http";
import {toast} from 'react-toastify';
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import Search from "../search";

const DietPreferencePage = () => {
    const [dietData, setdietData] = useState({ results: [], count: 0 });
    const [loding , setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const fetchData = async () => {
        try {
            const response = await http.get(`diet-preferences/?page=${currentPage + 1}`)
            setdietData(response.data);
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [currentPage]);

    
    const pageCount = Math.ceil(dietData.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = dietData?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // const handleSearch = (searchQuery) => {
    //     setSearchTerm(searchQuery);
    //     setCurrentPage(0);
    // };

    
    const handleDelete = async (id) => {
        setLoading(true)
        try {
            await http.delete(`diet-preference-admin/${id}/`);
            const updatedDietData = filteredData?.filter(diet => diet.id !== id);
            fetchData();
            setLoading(false);
            toast.success("Diet preference deleted successfully")
        } catch (error) {
            console.error("Error deleting unit:", error);
            setLoading(false);
            toast.error("Failed to delete diet preference")
        }
    };

    return(
        <Container>
            <div className={style.diet_container}>
                <div className={style.diet_page_head}>
                    <Heading name="Diet preference"/>
                </div>
                <div className="row">
                    <div className="col-5">
                        <DietPrefernceForm  onUpdate={fetchData} showImageField={true}/>
                    </div>
                    <div className="col-7">
                        <DietTable dietData={filteredData} onDelete={handleDelete} onUpdate={fetchData} />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default DietPreferencePage;