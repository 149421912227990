import React, { useEffect, useState } from "react";
import style from "./Meal-page.module.scss";
import DietPlan from "../plan-detail";
// import { Container } from "react-bootstrap";
import Heading from "../heading";
import MealForm from "../meal-add-form";
import MealTable from "../meal-table";
import { http } from "../../utilits/http";
import PlanDetails from "../plan-details";
import { useLocation, useParams } from "react-router-dom";

const MealPage = () => {
  const [addMeal, setAddMeal] = useState([]);

  const [data, setData] = useState("");

  const params = useParams();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const getData = () => {
    setLoading(true);
    http
      .get(`/user-diet-plan/${params?.planId}/`)
      .then((res) => {
        setLoading(false);
        if (res?.data) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //getting diet paln details
    getData();
  }, []);

  return (
    <div className={style.meal_page_container}>
      <div className={style.diet_plan_section}>
        <DietPlan data={data} onUpdate={getData} showedit={true} />
      </div>
      <div className={style.plan_section}>
        <PlanDetails data={data} />
      </div>
      <div className={style.meal_add_section}>
        <div className="row">
          <div className={`col-6 ${style.meal_form_col}`}>
            <Heading name="meal" />
            <MealForm onUpdate={getData} dietPlanId={params?.planId} userDietPlanId={params?.planId}/>
          </div>
          <div className={`col-6 ${style.meal_table}`}>
            <MealTable
              meals={data?.meals}
              addMeal={addMeal}
              onUpdate={getData}
              userDietPlanId={params?.planId}
              dietPlanId={data?.diet_plan?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealPage;
