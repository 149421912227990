import React, { useEffect, useState } from "react";
import style from "./Meal-add-form.module.scss";
import { FieldArray, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import InputField from "../input-field";
import { BiSolidTrash, BiSolidTrashAlt, BiTrash } from "react-icons/bi";
import { GrFormTrash } from "react-icons/gr";
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';

const MealForm = ({
  initialFormValues,
  onUpdate,
  dietPlanId,
  onClose,
  userDietPlanId,
}) => {
  const [dietPreference, setDietPrefernece] = useState([]);

  const [loading, setLoading] = useState(false);

  const [units, setUnits] = useState([]);

  const [unitsList, setUnitsList] = useState([]);

  const [formErr , setFormErr] = useState("");

  const [mealErr , setMealErr] = useState("");

  const [totalFoodCalories, setTotalFoodCalories] = useState(0);
  
  const onSubmitHandler = (values, { resetForm, setFieldValue }) => {
   
    setLoading(true);
    let _values = {
      ...values,
      user_plan_id: userDietPlanId,
      time: values.time + " " + values.ampm,
    };

    if (totalFoodCalories > values.total_calories) {
      setFormErr("Calories exceeded");
      setLoading(false); 
      return; 
    }
    setFormErr("");

    if (initialFormValues) {
      console.log(initialFormValues , 'initialformvalues')
      http
        .patch(`/meal/${values?.id}/`, _values)
        .then((res) => {
          onClose();
          setLoading(false);
          onUpdate();
          toast.success("Meal updated successfully")
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Failed to update meal")
          // console.log(err);
        });
    } else {
      http
        .post("/meal/", _values)
        .then((res) => {
          setLoading(false);
          onUpdate();
          resetForm();
          setFieldValue("food_item", []);
          toast.success("Meal created successfully")
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Failed to create meal")
          // console.log(err);
        });
    }
  };

  const handleMealAdd = (setFieldValue, values, resetForm) => {
    console.log(values.total_calories , 'calories');
    // console.log(calories , 'cal')
      if (!values.meal || !values.quantity || !values.unit) {
        setFormErr("Meal, Quantity, and Unit are required");
        return;
      }
      const isDuplicate = values.food_item.some(
        (item) => item.food_item === values.meal
      );

      if (isDuplicate) {
        setMealErr("Food item is already added");
        return;
      }
      // setMealErr("");
    
      const newItem = {
        food_item: values.meal,
        quantity: values.quantity,
        unit: values.unit,
        unit_name: units?.units_applicable?.find((unit) => unit?.unit_id == values.unit)?.unit,
      };
    
      values.food_item.push(newItem);
    
      const foodCalories = dietPreference?.find((diet) => diet?.id == newItem?.food_item)?.calories * newItem?.quantity;
      setTotalFoodCalories(totalFoodCalories + foodCalories);

      setMealErr("");
      setFormErr("");

      setFieldValue("food_item", values.food_item);
      setFieldValue("meal", "");
      setFieldValue("unit", "");
      setFieldValue("quantity", "");
  };
  
  const handleRemoveFoodItem = (remove, index, values) => {
    remove(index);
  
    const newTotalCalories = values.food_item.reduce((total, item, i) => {
      if (i !== index) {
        const foodCalories =
          dietPreference?.find((diet) => diet?.id === item?.food_item)?.calories ||
          0;
        return total + foodCalories * parseFloat(item.quantity);
      }
      return total;
    }, 0);
  
    setTotalFoodCalories(newTotalCalories);
  
    if (newTotalCalories <= values.total_calories) {
      setFormErr(""); 
    }
  };
  
  useEffect(() => {
    const getDietPreference = () => {
      setLoading(true);
      http
        .get("/food-item/")
        .then((res) => {
          if (res?.data?.results) {
            setDietPrefernece(res.data.results);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const getUnits = () => {
      setLoading(true);
      http
        .get("/unit-list/")
        .then((res) => {
          if (res?.data?.results) {
            setUnitsList(res.data.results);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    getUnits();

    getDietPreference();
  }, []);

  const INITAILVALUES = {
    name: "",
    time: "",
    food_item: [],
    total_calories: "",
    total_fat : "",
    total_proteins :"",
    total_carbohydrates:"",
    meal: "",
    quantity: "",
    unit: "",
    ampm: "AM",
  };

  const VALIDATION = Yup.object().shape({
    // name: Yup.string().required("Meal name is required").matches(/^[A-Za-z\s]+$/, 'Enter valid name').max(15, 'Meal name must be at most 15 characters'),
    name: Yup.string()
      .required("Name is required")
      .test('No-empty-spaces', 'Enter a valid name', (value) => {
      return value.trim() !== '';
    })
    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
    .max(20, 'Name must be at most 20 characters'),
    time: Yup.string().matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Invalid time format. Use HH:MM (10:00)"
    ),
    total_calories: Yup.number().typeError("Please enter a number").required(
      "Please enter the amount of calories to be consumed"
    ).typeError("please enter a number"),
    total_fat: Yup.number().typeError("Please enter a number").required(
      "Please enter the amount of fat to be consumed"
    ), 
    total_proteins: Yup.number().typeError("Please enter a number").required(
      "Please enter the amount of proteins to be consumed"
    ), 
    total_carbohydrates: Yup.number().required(
      "Please enter the amount of carbohydrates to be consumed"
    ).typeError("please enter a number"),  
    food_item: Yup.array()
    .of(
      Yup.object().shape({
        food_item: Yup.string().required("Food item is required"),
        quantity: Yup.string().required("Quantity is required").typeError("please enter a number"),
        unit: Yup.string().required("Unit is required"),
      })
    )
    .min(1, "Add at least one food item"),
    // meal: Yup.string().required("Select a meal"),
  });
  
  const formatInitailFormValues = (values) => {
    let _values = {
      ...values,
      time: values.time.split(" ")[0],
      ampm: values.time.split(" ")[1],
    };
    return _values;
  };

  // console.log("diet", dietPreference);

  return (
    <div className={style.form_container}>
      {loading && <Loading />}
      <div className="form_head">
        {initialFormValues ? "Edit meal" : "Add meal"}
      </div>
      <hr className="seperator" />
      <Formik
        initialValues={
          initialFormValues
            ? formatInitailFormValues(initialFormValues)
            : INITAILVALUES
        }
        validationSchema={VALIDATION}
        onSubmit={onSubmitHandler}
      >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        resetForm,
        }) => (
          <form onSubmit={handleSubmit} className={style.form_wrapper}>
            <div className="row">
              <div className={`col-6 ${style.form_field}`}>
                <InputField
                  type="text"
                  name="name"
                  value={values.name}
                  label="Meal"
                  placeholder="Name of meal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="small"
                  isRequired={true}
                />
                <div className="error_msg">
                  <ErrorMessage name="name" />
                </div>
              </div>

              <div className={`col-6 ${style.time_field}`}>
                <div className="row">
                  <div className="col-7">
                    <InputField
                      type="text"
                      name="time"
                      value={values.time}
                      label="Time of meal"
                      placeholder="00:00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="small"
                      isRequired={true}
                    />
                  </div>
                  <div className={`col-5 ${style.select_fld}`}>
                    <select
                      name="ampm"
                      id="ampm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ampm}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="error_msg">
                  <ErrorMessage name="time" />
                </div>
              </div>
              <div className="col-6">
                <div className={style.calory_field}>
                  <InputField
                    type="text"
                    name="total_calories"
                    value={values.total_calories}
                    label="Calories to be consumed"
                    placeholder="Enter value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                </div>
                <div className="error_msg">
                  <ErrorMessage name="total_calories" />
                </div>
              </div>
              <div className="col-6">
                <div className={style.calory_field}>
                    <InputField
                      type="text"
                      name="total_fat"
                      value={values.total_fat}
                      label="fat to be consumed"
                      placeholder="Enter value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="small"
                      isRequired={true}
                    />
                </div>
                <div className="error_msg">
                  <ErrorMessage name="total_fat" />
                </div>
              </div>
              <div className="col-6">
                <div className={style.calory_field}>
                    <InputField
                      type="text"
                      name="total_proteins"
                      value={values.total_proteins}
                      label="proteins to be consumed"
                      placeholder="Enter value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="small"
                      isRequired={true}
                    />
                </div>
                <div className="error_msg">
                  <ErrorMessage name="total_proteins" />
                </div>
              </div>
              <div className="col-6">
                <div className={style.calory_field}>
                  <InputField
                    type="text"
                    name="total_carbohydrates"
                    value={values.total_carbohydrates}
                    label="carbohydrates to be consumed"
                    placeholder="Enter value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                </div>
                <div className="error_msg">
                  <ErrorMessage name="total_carbohydrates" />
                </div>
              </div>
              <div className="col-12">
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-6">
                    <div className={`${style.select_wrapper}`}>
                      <label htmlFor="foodItem">Food preference<span className="image_req">*</span></label>
                      <select
                        name="meal"
                        className="form-control"
                        onChange={(e) => {
                          handleChange(e);
                          setUnits(
                            dietPreference.find(
                              (diet) => diet.id == e.target.value
                            )
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.meal}
                      >
                        <option value="">Select meal</option>
                        {dietPreference &&
                          dietPreference?.map((diet, index) => {
                            return (
                              <option value={diet?.id} key={index}>
                                {diet?.name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="error_msg">
                        <ErrorMessage name="meal" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      style={{ display: "flex" }}
                      className={style.form_small}
                    >
                      <div className={`${style.select_wrapper}`}>
                        <input
                          type="text"
                          name="quantity"
                          className="form-control"
                          placeholder="Quantity"
                          onChange={handleChange}
                          value={values.quantity}
                        />
                      </div>
                      <div>
                      <ErrorMessage name="quantity" />
                      </div>
                      <div className={`${style.select_unit_wrapper}`}>
                        <select
                          name="unit"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.unit}
                        >
                        <option value="">Unit</option>
                          {units?.units_applicable?.map((unit, index) => {
                            return (
                              <option value={unit?.unit_id} key={index}>
                                {unit?.unit}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <ErrorMessage name="unit" />
                      <div className={`${style.btn_wrapper}`}>
                        <button
                          type="button"
                          onClick={() => {
                            handleMealAdd(setFieldValue, values, resetForm);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mealErr && <div className="error_msg">{mealErr}</div>}
              {formErr && <div className="error_msg">{formErr}</div>}
              <div className={style.inner_form}>
                <div className="row"></div>
                <FieldArray name="food_item">
                  {({ push, remove }) => (
                    <div className="row">
                      <div className="col-12">
                        {values?.food_item?.map((item, index) => (
                          <div key={index} className={style.selected_meals}>
                            <div className={style.strong}>{index + 1}</div>
                            <div className={style.strong}>
                              <div className={style.meal_name}>
                                {
                                  dietPreference.find(
                                    (diet) => diet?.id == item?.food_item
                                  )?.name
                                }
                              </div>
                            </div>
                            <div>
                              {item.quantity}{" "}
                              {
                                unitsList?.find(
                                  (unit) => unit?.id == item?.unit
                                )?.name
                              }
                            </div>
                            {/* <div>
                              {dietPreference?.find(
                                (diet) => diet?.id == item?.food_item
                              )?.calories * item?.quantity}{" "}Kcal
                              {console.log(item?.quantity) }
                            </div> */}
                            <div>
                              {(() => {
                                const calories = dietPreference?.find((diet) => diet?.id == item?.food_item)?.calories * item?.quantity;
                                return calories + " Kcal";
                              })()}
                            </div>

                            <button
                              className={style.trash_btn}
                              onClick={() => {
                                handleRemoveFoodItem(remove, index, values);
                              }}
                              type="button"
                            >
                              <BiTrash />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="error_msg">
                  <ErrorMessage name="food_item" />
                </div>
                {/* {totalFoodCalories > values.total_calories && (
                  <div className="error_msg">Calory exceeded</div>
                )} */}
                <div className="row">
                  <div className="col-12"></div>
                </div>
              </div>
            </div>
            <div className="button_wrapper">
              <button
                className="input_cancel_btn"
                type="reset"
                onClick={() => {
                  initialFormValues ? onClose() : resetForm();
                }}
              >
                {initialFormValues ? "Cancel" : "Clear"}
              </button>
              <button className="input_add_btn" type="submit">
                {initialFormValues ? "Update" : "Create meal"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default MealForm;
