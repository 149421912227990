import React, { useEffect, useState } from "react";
import style from "./Plan-detail.module.scss";
import Heading from "../heading";
// import { LuEdit } from 'react-icons/lu';
import "../../styles/global.css";
import Modal from "../modal";
import EnquiryForm from "../enquiry-form";
import { useParams } from "react-router-dom";
import { http } from "../../utilits/http";
import { LiaUserEditSolid } from "react-icons/lia";
import Loading from "../loading";
import { BiEdit } from "react-icons/bi";

const DietPlan = ({ showButton, data ,showedit }) => {
  const [Data, setData] = useState();
  const [loading , setLoading] = useState(false);
  const { planId } = useParams();

  const [showModal, setShowModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await http.get(`user-diet-plan/${planId}/`);
      setData(response.data);
      // console.log(response.data)
    } catch (error) {
      console.log("error fetching data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [planId]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const dietGoals = Data?.diet_goals;
  const currentWeight = Data?.current_weight;
  const healthcondition = Data?.health_conditions;
  const targetWeight = Data?.target_weight;
  const dietPreferences = Data?.diet_preferences;
  const allergies = Data?.allergies;

  return (
    <div className={style.plan_detail_conainer}>
      {loading && <Loading />}
      <div className={style.page_head}>
        <div className={style.haeding_section}>
          <Heading name="diet plan" />
        </div>
        <div className={style.button_section}>
          {showButton && <button onClick={() => {}}>Back to List</button>}
        </div>
      </div>
      <div className={style.diet_edit}>
        Details
        {showedit && 
        <span>
          <button
            type="button"
            className={style.edit_button}
            onClick={openModal}
          >
            <BiEdit />
          </button>
        </span>
        }
      </div>
      <hr className="seperator" />
      <div className={style.plan_detail}>
        <div className="row">
          <div className="col-4">
            <div className={style.item_list}>
              <div className={style.item_name}>Goals</div> :
              <div className={style.item_value}>
                {dietGoals?.map((goal, index) => (
                  <div key={index}>{goal.name}</div>
                ))}
              </div>
            </div>
            <div className={style.item_list}>
              <div className={style.item_name}>Diet Preferences </div>{" "}
              <span>:</span>
              <div className={style.item_value}>
                {dietPreferences?.map((preference, index) => (
                  <div key={index}>{preference.name}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className={style.item_list}>
              <div className={style.item_name}>Weight </div> :
              <div className={style.item_value}>{currentWeight} kg</div>
            </div>
            <div className={style.item_list}>
              <div className={style.item_name}>Target Weight </div>
              : 
             <div className={style.weight_value}>{targetWeight} kg</div>
            </div>
          </div>
          <div className="col-4">
            <div className={style.item_list}>
              <div className={style.item_name}>Health Condition </div>
              <span>:</span>
              <div className={style.item_value}>
                {healthcondition?.map((conditions, index) => (
                  <div key={index}>{conditions.name}</div>
                ))}
              </div>
            </div>
            <div className={style.item_list}>
              <div className={style.item_name}>Allergies </div> :
              <div className={style.item_value}>
                {allergies?.map((allergies, index) => (
                  <div key={index}>{allergies.name}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal showClose onClose={closeModal} isLarge={true}>
          <EnquiryForm initialFormValues={Data} userPlanId={planId} onClose={closeModal} onUpdate={fetchData} />
        </Modal>
      )}
    </div>
  );
};

export default DietPlan;
