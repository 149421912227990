import React , {useState , useEffec} from "react";
import style from './Diet-preference-form.module.scss';
import { Formik , ErrorMessage } from "formik";
import * as Yup from 'yup';
import InputField from "../input-field";
import {MdUploadFile , MdClose} from 'react-icons/md';
import { http } from "../../utilits/http";
import ImageField from "../image-fld";
import Loading from "../loading";
import { toast } from 'react-toastify';

const DietPrefernceForm = ({ 
    initialFormValues, 
    addNewDiet ,
    onUpdate , 
    isEdit , 
    closeModal 
}) => {
    const [imageFile, setImageFile] = useState(null);
    const [imageError , setImageError] = useState(null);
    const [showError , setShowError] = useState("");
    const formModeClass = isEdit ? 'edit-mode' : 'add-mode';
    const formModeHeading = isEdit ? 'Edit diet preference' : 'Add diet preference';
    const [loading, setLoading] = useState(false);
    const [imageName, setImageName] = useState(initialFormValues ? initialFormValues.image_name : "");

    const onSubmitHandler = async (values, { resetForm }) => {
        if (!isEdit || (isEdit && imageFile)) {
            if (!imageFile) {
                setImageError("please select an image");
                return;
            } else if (imageError) {
                return;
            }
        }
        setShowError("");
    
        let formData = new FormData();
        formData.append("name", values.name.toLowerCase());
    
        if (isEdit && imageFile) {
            formData.append("image", imageFile);
        }
        if (isEdit) {
            formData.append("id", initialFormValues.id);
            formData.append("image_name", values.image_name);
            http
                .patch(`diet-preference-admin/${initialFormValues.id}/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    onUpdate();
                    resetForm('data:', formData);
                    closeModal();
                    setLoading(false);
                    toast.success('Diet preference edited successfully');
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    if (err.response) {
                        toast.error(err.response.data.message || 'Failed to add diet preference');
                        setShowError(err.response.data.message);
                    } else {
                        toast.error('Failed to add diet preference');
                        setShowError("An error occurred while sending the request");
                    }
                });
        } else {
            formData.append("image", imageFile);
            http.post("diet-preference-admin/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                onUpdate();
                resetForm('data:', formData);
                setImageFile("");
                setImageName(values.image_name);
                console.log('data', res);
                toast.success('Diet preference created successfully');
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                // setShowError(err?.response?.data);
                // toast('failed to create diet preference');
                if (err.response) {
                    toast.error(err.response.data.message || 'Failed to add diet preference');
                    setShowError(err.response.data.message);
                } else {
                    toast.error('Failed to add diet preference');
                    setShowError("An error occurred while sending the request");
                }
            });
        }
    };       

    const  handleClear = (resetForm) => {
        resetForm(initialFormValues);
        setImageFile("");
    }
    const INITIALVALUES = {
        name: initialFormValues ? initialFormValues.name : "",
        image: initialFormValues ? initialFormValues.image : "",
        image_name: initialFormValues ? initialFormValues.image_name : "",
    };
    return(
        <div className={`form_container ${formModeClass}`}>
            {loading && <Loading />}
            <div className="form_head">
                {formModeHeading}
            </div>
            <hr className="seperator"/>
            <Formik
                initialValues={initialFormValues ? initialFormValues : INITIALVALUES}
                validationSchema={Yup.object().shape({
                    // name: Yup.string().required("Item name is required").matches(/^[A-Za-z\s]+$/, 'Enter a valid name').max(30, 'Diet preference must be at most 30 characters'),
                    name: Yup.string()
                    .required("Name is required")
                    .test('no-empty-spaces', 'Please enter a valid name ', (value) => {
                      return value.trim() !== ''; 
                    })
                    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
                    .max(30, 'Diet preference must be at most 30 characters'),
                })}
                onSubmit={onSubmitHandler}
            >
                {({ values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    resetForm
                })=>(
                    <form onSubmit={handleSubmit} className="form_wrapper" encType="multipart/form-data">
                        <div className={style.form_field}>
                            <InputField 
                                type="text"
                                name="name"
                                value={values.name}
                                label="preference name"
                                placeholder="name of the preference"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form_input_fld"
                                width="large"
                                isRequired={true}
                            />
                        </div>
                        <div className="error_msg">
                            <ErrorMessage name="name" />
                        </div>
                        <div className="form_img_field">
                            <div className="img_label"><label htmlFor="image">Image<span className="image_req">*</span></label></div>
                            <div className="add_img_block">
                                <label className="label_img">
                                    <input
                                        type="file"
                                        name="image"
                                        placeholder="upload image"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            setImageFile(file);
                                            const allowedFormats = ["image/jpeg", "image/png"];
                                            if (!allowedFormats.includes(file.type)) {
                                                setImageError("Invalid file format. Please upload a JPEG or PNG image.");
                                            } else {
                                                setImageError("");
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        width="large"
                                    />
                                     <div className="selected_file_name">
                                        {imageFile && (
                                            <div className="selected">
                                                <p>{imageFile.name}</p>
                                                <div className="file_close">
                                                    <MdClose
                                                        className="close_icon"
                                                        onClick={() => {
                                                            setImageFile(null);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {!imageFile && initialFormValues && initialFormValues.image_name && (
                                            <div className="updated">
                                                <p>{initialFormValues.image_name}</p>
                                                    <MdClose
                                                        className="close_icon"
                                                        onClick={() => {
                                                            setImageFile(null);
                                                        }}
                                                    />
                                            </div>
                                        )}
                                        {!imageFile && (!initialFormValues || !initialFormValues.image_name) && (
                                            <div className="upload">
                                                <p>Upload image</p>
                                                <MdUploadFile />
                                            </div>
                                        )}
                                    </div>
                                </label>
                                {imageError && <p className="error_msg">{imageError}</p>}
                                <p className="image_size">Max file size is 5MB</p>
                            </div>
                        </div>                    
                        {/* <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div> */}
                        <div className="button_wrapper">
                            {!initialFormValues && ( 
                                <button className="input_clear_btn" type="button" onClick={() => handleClear(resetForm)}>
                                    Clear
                                </button>
                            )}
                            <button className="input_add_btn" type="submit">
                                {initialFormValues ? "Update" : "Add"}
                            </button>
                            {initialFormValues && ( 
                                <button className="input_cancel_btn" type="button" onClick={closeModal}>
                                    Cancel
                                </button>
                            )}
                        </div>
                        
                    </form>
                    
                )}
            </Formik>
        </div>
    )
}
export default DietPrefernceForm;