import React, { useState, useEffect } from "react";
import style from './Goal-page.module.scss';
import Container from "../container";
import GoalForm from "../goal-form";
import GoalTable from '../goal-table';
import Heading from "../heading";
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import Search from "../search";

const GoalPage = () => {
    const [goalData, setGoalData] = useState({ results: [], count: 0 });
    // const [initialFormValues, setInitialFormValues] = useState({ name: "" });
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState("");

    const fetchData = async () => {
        try {
            const response = await http.get(`diet-goals/?search=${searchTerm}&page=${currentPage + 1}`);
            setGoalData(response.data); 
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
      
    useEffect(() => {
        fetchData();
    }, [searchTerm,currentPage]); 

    const pageCount = Math.ceil(goalData.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = goalData?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    const handleDelete = async (id) => {
        setLoading(true)
        try {
            await http.delete(`diet-goals-admin/${id}/`);
            // const updatedGoalData = filteredData?.filter(goal => goal.id !== id);
            // setGoalData(updatedGoalData);
            setLoading(false)
            toast.success("Goal deleted successfully")
            fetchData();
        } catch (error) {
            console.error("Error deleting goal:", error);
            setLoading(false)
            toast.error("Failed to delete goal")
        }
    };

    return(
        <Container>
            <div className={style.goal_container}>
            {loading && <Loading />}
                <div className={style.goal_page_head}>
                    <Heading name="goal"/>
                </div>
                <div className="row">
                    <div className="col-5">
                        <GoalForm onUpdate={fetchData}  />
                    </div>
                    <div className="col-7">
                        <GoalTable goalData={filteredData} onDelete={handleDelete} onUpdate={fetchData} />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default GoalPage;

