import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UnitPage from "../unit-page";
import GoalPage from "../goal-page";
import DietPreferencePage from "../Diet-preference-page";
import HealthConditionPage from "../health-condition-page";
import AllergyPage from "../allergies-page";
import DietList from "../diet-list-admin";
import DietPageFc from "../diet-page-fc";
import FoodAddAdmin from "../food-listing-and-adding";
import FoodListFc from "../food-listing-fc";
import Plan from "../plan-choosing";
import MealPage from "../Meal-page";
import AcceptFoodCustomer from "../accept-food-customer";
import AcceptFoodFc from "../accept-food-fc";
import RequestListAdmin from "../request-listing-admin";
import RequestPage from "../request-page-fc";
import EnquiryView from "../view-enquiry";
import Layout from "../layout";
import UserDietPlanCreate from "../user-diet-plan-create";
import MealListAdmin from "../meal-listing-admin";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />} />

      {/*ADMIN */}
      <Route
        path="/diet-listing-admin"
        element={
          <Layout>
            <DietList />
          </Layout>
        }
      />
      <Route
        path="/food-listing-admin"
        element={
          <Layout>
            <FoodAddAdmin />
          </Layout>
        }
      />
      <Route
        path="/request-listing-admin"
        element={
          <Layout>
            <RequestListAdmin />
          </Layout>
        }
      />
      <Route
        path="/units"
        element={
          <Layout>
            <UnitPage />
          </Layout>
        }
      />
      <Route
        path="/goals"
        element={
          <Layout>
            <GoalPage />
          </Layout>
        }
      />
      <Route
        path="/health-condition"
        element={
          <Layout>
            <HealthConditionPage />
          </Layout>
        }
      />
      <Route
        path="/allergies"
        element={
          <Layout>
            <AllergyPage />
          </Layout>
        }
      />
      <Route
        path="/diet-preference"
        element={
          <Layout>
            <DietPreferencePage />
          </Layout>
        }
      />
      {/*ADMIN  END */}

      {/*FITNES CENTER*/}
      <Route
        path="/user-diet-plan-create"
        element={
          <Layout>
            <UserDietPlanCreate />
          </Layout>
        }
      />
      <Route
        path="/diet-listing-fc"
        element={
          <Layout>
            <DietPageFc />
          </Layout>
        }
      />
      <Route
        path="/food-listing-fc"
        element={
          <Layout>
            <FoodListFc />
          </Layout>
        }
      />
      <Route
        path="/request-listing-fc"
        element={
          <Layout>
            <RequestPage />
          </Layout>
        }
      />
      {/*FITNES CENTER END*/}

      <Route
        path="/plan-choosing/:planId/"
        element={
          <Layout>
            <Plan />
          </Layout>
        }
      />
      <Route
        path="/meals/:planId"
        element={
          <Layout>
            <MealPage />
          </Layout>
        }
      />
      <Route
        path="/accept-food-customer/:id/"
        element={
          <Layout>
            <AcceptFoodCustomer />
          </Layout>
        }
      />
      <Route
        path="/accept-food-fc/:id/"
        element={
          <Layout>
            <AcceptFoodFc />
          </Layout>
        }
      />
      <Route
        path="/view-enquiry/:planId/"
        element={
          <Layout>
            <EnquiryView />
          </Layout>
        }
      />
      <Route
        path="/meal-list/:planId/"
        element={
          <Layout>
            <MealListAdmin />
          </Layout>
        }
      />
    </Routes>
    
  );
};
export default AppRouter;
