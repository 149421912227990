import React, { useState } from "react";
import style from './Search.module.scss';
import '../../styles/global.css';
import {BiSearch} from 'react-icons/bi';

const Search = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(searchQuery);
  };

  return (
        <div className={style.search}>
            <form onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="Search here"
                    value={searchQuery}
                    onChange={handleInputChange}
                />
                <button type="submit" className={style.search_btn}><BiSearch /></button>
            </form>
        </div>
  );
};

export default Search;
