import React, { useState } from "react";
import style from "./Diet-add-form.module.scss";
import { Formik, ErrorMessage } from "formik";
import InputField from "../input-field";
import * as Yup from "yup";
import "../../styles/global.css";
import { MdUploadFile , MdClose } from "react-icons/md";
import Loading from "../loading";
import { http } from "../../utilits/http";
import {toast} from 'react-toastify';

const DietAddForm = ({
  initialFormValues,
  addDiet,
  onUpdate,
  updatedData,
  isEdit,
  onClose,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  const formModeClass = isEdit ? 'edit-mode' : 'add-mode';
  const formModeHeading = isEdit ? 'Edit diet' : 'Add diet';
  const [showError , setShowError] = useState("");
  const [valueErr , setValueErr] = useState("");

  const resetImage = () => {
    setSelectedImage(null)
  }

  const onSubmitHandler = (values, { resetForm }) => {
    if (!isEdit || (isEdit && selectedImage)) {
      if (!selectedImage) {
          setImageError("please select an image");
          return;
      } else if (imageError) {
          return;
      }
    }
    if(values.duration == 0){
       setValueErr("Please enter a number above 0")
    }
    
    setValueErr("");
    setImageError("");
    // setShowError("");

    let formData = new FormData();
    formData.append("name", values.name.toLowerCase());
    formData.append("image", selectedImage);
    formData.append("dietitian_name", values.dietitian_name);
    formData.append("duration", values.duration);
    formData.append("dd_mm", values.dd_mm);
    formData.append("price", values.price);
    setLoading(true);
    if (initialFormValues) {
      formData.append('id',initialFormValues.id);
        http
        .patch(`/diet-plan/${initialFormValues.id}/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
          onUpdate();
          setLoading(false);
          onClose();
          resetForm('data:',formData);
          toast.success("Diet edited successfully")
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            toast.error(err.response.data.message || 'Failed to add diet preference');
            setShowError(err.response.data.message);
          } else {
              toast.error('Failed to add diet preference');
              setShowError("An error occurred while sending the request");
          }        
        });
        } else {
          http
            .post("/diet-plan/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                setLoading(false);
                resetForm();
                onUpdate();
                setSelectedImage("")
                resetImage();
                console.log('response' , res)
                toast.success("Diet created successfully")
            })
            .catch((err) => {
              setLoading(false);
              if (err.response) {
                toast.error(err.response.data.message || 'Failed to add diet preference');
                setShowError(err.response.data.message);
            } else {
                toast.error('Failed to add diet preference');
                setShowError("An error occurred while sending the request");
            }
            });
      }
    };

  const handleClearClick = (resetForm) => {
    resetForm(initialFormValues);
    setSelectedImage("")
  };

  const INITAILVALUES = {
    name: "",
    image: "",
    dietitian_name: "",
    duration: "",
    dd_mm: "month",
    price: "",
    status: "pending",
  };

  const VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required("Diet name is required")
      .test('no-empty-spaces', 'Please enter a valid name', (value) => {
      return value.trim() !== ''; 
    })
    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
    .max(25, 'Name must be at most 25 characters'),
    dietitian_name: Yup.string()
      .required("Dietitian's name is required")
      .test('No-empty-spaces', 'Please enter a valid name', (value) => {
        return value.trim() !== ''; 
    })
    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
    .max(25, 'Name must be at most 25 characters'),
    duration: Yup.number().typeError('Please enter a number').required("Duration is required").min(1, 'Duration must be above 0'),
    price: Yup.number().typeError('Please enter a number').required("Price is required").min(1, 'Price must be above 0'),
  });

  return (
      <div className={`form_container ${formModeClass}`}>
        {loading && <Loading />}
            <div className="form_head">
                {formModeHeading}
            </div>
      <hr className="seperator" />
      <Formik
        initialValues={initialFormValues ? initialFormValues : INITAILVALUES}
        validationSchema={VALIDATION}
        onSubmit={onSubmitHandler}
      >
        {({ values, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <form onSubmit={handleSubmit} className="form_wrapper">
            <div className={style.form_field}>
              <InputField
                type="text"
                name="name"
                value={values.name}
                label="Diet name"
                placeholder="Name of the diet"
                onChange={handleChange}
                onBlur={handleBlur}
                width="large"
                isRequired={true}
              />
            </div>
            <div className="error_msg">
              <ErrorMessage name="name" />
            </div>
            {!isEdit && (
              <div className="form_img_field">
                <div className="img_label">
                  <label htmlFor="image">Diet Image<span className="image_req">*</span></label>
                </div>
                <div className="add_img_block">
                  <label className="label_img" htmlFor="image_label">
                      <input 
                          type="file"
                          name="image"
                          id="image_label"
                          placeholder="upload image"
                          onBlur={handleBlur}
                          width="large"
                          onChange={
                            (e) => {
                              const file = e.target.files[0];
                              setSelectedImage(file);
                              console.log('image',selectedImage)
                              if (!file) {
                                setImageError("Image is required");
                              } else {
                                const allowedFormats = ["image/jpeg", "image/png"];
                                if (!allowedFormats.includes(file.type)){
                                  setImageError("Invalid file format. Please upload a JPEG or PNG image")
                                }else{
                                  setImageError("");
                                }
                              }
                            }
                          }
                      />
                    <div className="selected_file_name">
                    <div className="image_upload">
                        {selectedImage ? (
                            <div className="selected">
                                <p>{selectedImage.name}</p>
                                <div className="file_close"><MdClose
                                    className="close_icon"
                                    onClick={() => setSelectedImage(null)}
                                /></div>
                            </div>
                        ) : (
                            <div className="upload">
                                <p>Upload image</p>
                                <>< MdUploadFile /></>
                            </div>
                        )}
                      </div>
                  </div>
                  </label>
                  <p className="image_size">Max file size is 5MB</p>
                </div>
              </div>
            )}
            <div className="error_msg">
              {imageError && <span>{imageError}</span>}
              <ErrorMessage name="image" />
            </div>
            <div className={style.form_input_field}>
              <InputField
                type="text"
                name="dietitian_name"
                value={values.dietitian_name}
                label="Dietician name"
                placeholder="Name of the dietician"
                onChange={handleChange}
                onBlur={handleBlur}
                width="large"
                isRequired={true}
              />
            </div>
            <div className="error_msg">
              <ErrorMessage name="dietitian_name" />
            </div>
            <div className="row">
              <div className="col-4">
                <div className={style.form_field}>
                  <InputField
                    type="text"
                    name="duration"
                    value={values.duration}
                    label="Duration"
                    placeholder="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="large"
                    isRequired={true}
                  />
                </div>
              </div>
              <div className={`col-8 ${style.select_container}`}>
                <select
                  name="dd_mm"
                  className={style.select_field}
                  value={values.dd_mm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="month">Month</option>
                  <option value="days">Days</option>
                </select>
              </div>
              <div className="col-12">
                <div className="error_msg">
                  <ErrorMessage name="duration" />
                </div>
              </div>
            </div>
            {valueErr && <span>{valueErr}</span>}
            <div className={style.plan_form_field}>
              <InputField
                type="text"
                name="price"
                value={values.price}
                label="plan price"
                placeholder="Enter the price"
                onChange={handleChange}
                onBlur={handleBlur}
                width="large"
                isRequired={true}
              />
            </div>
            <div className="error_msg">
              <ErrorMessage name="price" />
            </div>
            <div className="button_wrapper">
              <button
                className="input_cancel_btn"
                type="button"
                onClick={() =>
                  initialFormValues ? onClose() : handleClearClick(resetForm)
                }
              >
                {initialFormValues ? "Cancel" : "Clear"}
              </button>

              <button className="input_add_btn" type="submit">
                {initialFormValues ? "Update" : "Add"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default DietAddForm;
