import React, { useState, useEffect } from "react";
import style from "./Plan-choosing.module.scss";
import DietPlan from "../plan-detail";
import Container from "../container";
import Heading from "../heading";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineClockCircle } from "react-icons/ai";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { BiLock } from "react-icons/bi";
import { http } from "../../utilits/http";
import Modal from "../modal";
import { useParams } from "react-router-dom";
import Loading from "../loading";
import sucsess from '../../assets/images/check (2) 1.png'
import { useNavigate } from "react-router-dom";

const Plan = () => {
  const [courseData, setCourseData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { planId } = useParams();
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [modalData, setModalData] = useState({ is_shown: false, planId: null });

  const navigate = useNavigate();
  const {id} = useParams();

  const handleCourseChange = (plan_id, setFieldValue) => {
    setFieldValue("plan_id", plan_id);
  };

  let userPlanId;

  const openModalWithData = (is_shown, planId) => {
    setModalData({ is_shown, planId });
    setIsModalOpen(true);
  };

 

  useEffect(() => {
    http
      .get(`user-diet-plan/${planId}/`)
      .then((response) => {
        const userData = response.data;
        const userPlanId = userData.diet_plan.id;
        const duration = userData.diet_plan.duration;
        const paymentMethod = "cash";
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    http.get("diet-plan/").then((res) => {
      setCourseData(res?.data?.results);
    });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };
 const closeModal = () => {
  setIsModalOpen(false);
 }

  const handleFormSubmit = (values) => {
    setLoading(true);
    http
      .post("diet-payment-detail/", {...values, user_plan_id: planId})
      .then((response) => {
        console.log("Payment successful:", response.data);
        setLoading(false);

        if (response.data.is_shown === false ) {
          openModalWithData(response.data.is_shown, planId); 
        }

      })
      .catch((error) => {
        console.error("Payment failed:", error);
        setLoading(false);
      });
  };


  const handleOKButtonClick = () => {
    const { is_shown, planId } = modalData;
    http
      .patch(`is_shown/${planId}/`)
      .then((response) => {
        console.log("API Response:", response.data);
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setIsModalOpen(false);
      });
      navigate(`/meals/${planId}/`);
  };


  return (
    <div>
      {loading && <Loading />}
      <div className={style.plan_detail_container}>
        <div className={style.plan_details_section}>
          <DietPlan showButton={false} showedit={true}/>
        </div>
        <div className={style.plan_form_section}>
          <div className={style.heading_section}>
            <Heading name="Choose Diet plan" />
          </div>
          <Formik
            initialValues={{
              plan_id: "",
              payment_method: "",
            }}
            validationSchema={Yup.object().shape({
              plan_id: Yup.string().required("Please select a course"),
              payment_method: Yup.string().required(
                "Please select a Payment Method"
              ),
            })}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className={`col-6 ${style.course_section}`}>
                    <div className={style.field}>
                      <div>
                        <label htmlFor="plan_id">Diet plan name*</label>
                      </div>
                      <select
                        as="select"
                        id="plan_id"
                        name="plan_id"
                        onChange={(e) =>
                          handleCourseChange(e.target.value, setFieldValue)
                        }
                      >
                        <option value="">Select a diet plan</option>
                        {courseData.map((course) => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </select>
                      <div className="error_msg">
                        <ErrorMessage name="plan_id" />
                      </div>
                    </div>
                    <div className={style.value}>
                      <span className={style.icon_span}>
                        <AiOutlineClockCircle />
                      </span>
                      <span className={style.course_label}>Duration</span>:
                      <span className={style.value_sec}>
                        {values.plan_id
                          ? `${
                              courseData.find(
                                (course) =>
                                  course.id === parseInt(values.plan_id)
                              ).duration
                            } ${
                              courseData.find(
                                (course) =>
                                  course.id === parseInt(values.plan_id)
                              ).dd_mm
                            }`
                          : ""}
                      </span>
                    </div>
                    <div className={style.value}>
                      <span className={style.icon_span}>
                        <LiaRupeeSignSolid />
                      </span>
                      <span className={style.course_label}>Price</span>:
                      <span className={style.value_sec}>
                        {values.plan_id
                          ? courseData.find(
                              (course) => course.id === parseInt(values.plan_id)
                            ).price
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className={`col-6 ${style.course_paln}`}>
                    <label>Payment Method</label>
                    <div className={style.payment_method}>
                      <div className={style.radio_field}>
                        <input
                          id="cash"
                          type="radio"
                          name="payment_method"
                          value="cash"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <label htmlFor="cash">Cash payment</label>
                      </div>
                    </div>
                    <div className="error_msg">
                      <ErrorMessage name="payment_method" />
                    </div>
                    <div className={style.process_btn}>
                      <button type="submit">Process</button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          {paymentSuccess && paymentSuccess.is_shown && (
            <div>
              Payment successful:
              <pre>{JSON.stringify(paymentSuccess, null, 2)}</pre>
            </div>
          )}
        </div>
        <div className={style.add_meal}>
          <span className={style.meal_name}>Add meal</span>
          <span className={style.meal_icon}>
            <BiLock />
          </span>
          <span className={style.meal_msg}>
            Please process the payment first, and then proceed to add the meal
          </span>
        </div>
        {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {/* Modal content */}
          <div className={style.succes_modal}>
            <div className={style.success_img}>
                <img src={sucsess} />
            </div>
            <div className={style.success_head}>Payment successful!</div>
            <p className={style.success_msg}>The meal adding option is now unlocked. Feel free to start adding dishes!</p>
            <button className={style.modal_btn} onClick={handleOKButtonClick}>ok</button>
          </div>
        </Modal>
      )}
      </div>
    </div>
  );
};
export default Plan;
