import React, { useState, useEffect } from "react";
import style from './Unit-page.module.scss';
import Heading from "../heading";
import UnitTable from "../unit-table";
import Unitform from "../Unit-form";
import Container from '../container';
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import Search from "../search";

const UnitPage = () => {
    const [unitData, setUnitData] =  useState({ results: [], count: 0 });
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
    
    const fetchData = async () => {
        try {
            const response = await http.get(`unit-list/?search=${searchTerm}&page=${currentPage + 1}`);
            setUnitData(response.data); 
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData()
    }, [searchTerm , currentPage]);

    const pageCount = Math.ceil(unitData.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = unitData?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };
    
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await http.delete(`unit/${id}/`);
            fetchData();
            setLoading(false);
            toast.success("Unit deleted successfully")
        } catch (error) {
            console.error("Error deleting unit:", error);
            setLoading(false);
            toast.error("Failed to delete unit");
        }
    };

    return (
        <Container>
            <div className={style.unit_container}>
                {loading && <Loading />}
                <div className={style.unit_page_head}>
                    <Heading name="unit" />
                </div>
                <div className="row">
                    <div className="col-5">
                        <Unitform onUpdate={fetchData}/>
                    </div>
                    <div className="col-7">
                        <UnitTable unitData={filteredData} onDelete={handleDelete} />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default UnitPage;

