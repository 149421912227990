import React , {useState ,useEffect} from "react";
import style from './Accept-food-customer.module.scss';
import FoodAddForm from "../food-add-form";
import ReqTableCustomer from "../customer-accept-food-table";
import Container from "../container";
import Heading from "../heading";
import Search from "../search";
import { http } from "../../utilits/http";
import { useParams } from "react-router-dom";
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";

const AcceptFoodCustomer = () => {
    const [reqFood, setReqFood] = useState({ results: [], count: 0 });
    const [data , setData] = useState("");
    const [selectedItem , setSelectedItem] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const {id} = useParams();
    const selectedFoodItemId = parseInt(id);

    const fetchData = async () => {
        try {
            const response = await http.get(`food-item/?search=${searchTerm}&page=${currentPage + 1}`)
            setReqFood(response.data);
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }
    
    const getData = async () => {
        http.get(`fitness-food-request/`).then((res)=>{
            const foundItem = ('data', res?.data?.results.find((item)=>item.id == selectedFoodItemId)) ;
            setSelectedItem(foundItem)
        }).catch((err)=>{
            console.log('Error fetching data', err);
        })
    }

    useEffect(() => {
        getData()
        fetchData()
    },  [searchTerm,currentPage]);
    

    const fetchListData = async () => {
        try {
            const response = await http.get(`fitness-food-request/?search=${searchTerm}&page=${currentPage + 1}`);
            setData(response.data.results);
            // console.log(response.data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchListData()
    },[] );

    const pageCount = Math.ceil(reqFood.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = reqFood?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };
      
    const handleDelete = async (id) => {
        try {
            await http.delete(`food-item-create/${id.id}/`);
            fetchData();
        } catch (error) {
            console.error("Error deleting unit:", error);
        }
    };
    
    const handleAcceptRequest = async (name) => {
        try {
          const requestData = {
            id: selectedFoodItemId,
            status: "ACCEPT",
            name: name,
          };
      
          await http.patch(`approve-food-request/${selectedFoodItemId}/`, requestData);
      
          setData((prevSelectedItem) => ({
            ...prevSelectedItem,
            status: "ACCEPT", // Update the status here
          }));
        } catch (error) {
          console.log('Error accepting food:', error);
        }
      };
      
   
    return(
            <div className={style.acceptfood_container}>
                <div>
                    <Search onSearch={handleSearch} />
                </div>
                <div>
                    <Heading name="food" />
                </div>
                <div className="row">
                    <div className="col-6">
                    <FoodAddForm
                        showImageField={true}
                        addFoodItem={filteredData}
                        onUpdate={fetchData}
                        initialFormValues={selectedItem}
                        onAcceptRequest={handleAcceptRequest} 
                        isAcceptForm={true}
                    />
                    </div>
                    <div className={`col-6 ${style.food_add_form}`}>
                        <ReqTableCustomer reqFood={filteredData} onUpdate={fetchData} onDelete={handleDelete}/>
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
    )
}
export default AcceptFoodCustomer;