import React, { useEffect, useState } from "react";
import style from "./Enquiry-form.module.scss";
import { Formik, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import MultiSelectDropdown from "../dropdown-radio";
import { http } from "../../utilits/http";
import MultiSelectBox from "../multi-select-box";
import { useNavigate } from "react-router-dom";
import Loading from "../loading";

const EnquiryForm = ({
  initialFormValues,
  isCreate,
  customerId,
  userPlanId,
  onClose,
  onUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const [dietpreferencedata, setDietPreferenceData] = useState([]);
  const [healthData, setHealthData] = useState([]);
  const [allergiesData, setAllergiesData] = useState([]);

  const navigate = useNavigate();

  const handleClear = (resetForm ) => {
    resetForm(initialFormValues)
  };

  const getDietGoals = () => {
    setLoading(true);
    http
      .get("diet-goals/")
      .then((response) => {
        setGoalData(response?.data?.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data from api:", error);
      });
  };

  const getAllergies = () => {
    setLoading(true);
    http
      .get("allergies/")
      .then((response) => {
        setLoading(false);
        setAllergiesData(response.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data from api:", error);
      });
  };

  const getHelathCondition = () => {
    setLoading(true);
    http
      .get("health-conditions/")
      .then((response) => {
        setLoading(false);
        setHealthData(response.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data from api:", error);
      });
  };

  const dietPreferences = () => {
    setLoading(true);
    http
      .get("diet-preferences/")
      .then((response) => {
        setLoading(false);
        setDietPreferenceData(response.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data from api:", error);
      });
  };

  useEffect(() => {
    getDietGoals();
    getAllergies();
    getHelathCondition();
    dietPreferences();
  }, []);

  const onSubmitHandler = (values, { resetForm }) => {
    setLoading(true);
    let _value = {
      ...values,
      diet_goals: [parseInt(values.diet_goals)],
      customer: customerId,
    };
    if(isCreate) {
      http
        .post(`/user-diet-plan-create/?user=${customerId}`, _value)
        .then((res) => {
          setLoading(false);
          if (res?.data?.id) {
            navigate(`/plan-choosing/${res.data.id}`);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      http
        .patch(`/user-diet-plan-create/${userPlanId}/`, _value)
        .then((res) => {
          setLoading(false);
          onUpdate();
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const formatInitailValues = (values) => {
    const getId = (arr) => {
      let _arr = arr.map((item) => {
        return item.id ? item.id : item;
      });
      return _arr;
    };

    let _values = {
      ...values,
      diet_goals: getId(values.diet_goals),
      health_conditions: getId(values.health_conditions),
      allergies: getId(values.allergies),
      diet_preferences: getId(values.diet_preferences),
    };
    return _values;
  };

  const VALIDATION = Yup.object().shape({
    diet_goals: Yup.number().required("Please select a goal").typeError("Please select a goal"),
    name: Yup.string().required("Enter a name for the diet"),
    // dietPreference: Yup.array().min(1, "Select at least one diet preference"),
    // healthCondition: Yup.array(),
    // allergies: Yup.array().required(''),
    current_weight: Yup.number().typeError("Please enter a number").required("Enter your current weight"),
    target_weight: Yup.number().typeError("Please enter a number").required("Enter your target weight"),
  });

  const INITIALVALUES = {
    diet_goals: "",
    diet_preferences: [],
    health_conditions: [],
    allergies: [],
    target_weight: "",
    current_weight: "",
    name: "",
  };

  return (
    <div className="form_container">
      {loading && <Loading />}
      <div className="form_head">
        {isCreate ? "Enter details" : "Edit Details"}
      </div>
      <hr className="seperator" />
      <Formik
        initialValues={
          initialFormValues
            ? formatInitailValues(initialFormValues)
            : INITIALVALUES
        }
        validationSchema={VALIDATION}
        onSubmit={onSubmitHandler}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form_fields}>
              <div className={style.form_field}>
                <label>Name<span className="image_req">*</span></label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter a name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="error_msg">
                <ErrorMessage name="name" className="error" />
              </div>
              <div className={style.form_field}>
                <label htmlFor="goal">Goal Name<span className="image_req">*</span></label>
                <select
                  name="diet_goals"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.diet_goals}
                >
                  <option value="" disabled>
                    Choose goal
                  </option>
                  {goalData.map((goal, index) => {
                    return (
                      <option value={goal?.id} key={index}>
                        {goal?.name}
                      </option>
                    );
                  })}
                </select>
                <div className="error_msg">
                  <ErrorMessage name="diet_goals" className="error" />
                </div>
              </div>
              <div className={style.form_field}>
                <label>Allergies</label>
                <MultiSelectBox
                  options={allergiesData}
                  placeHolder="Choose the allergies"
                  name="allergies"
                  values={values.allergies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={style.form_field}>
                <label>Health condition</label>
                <MultiSelectBox
                  options={healthData}
                  placeHolder="Choose the health condition"
                  name="health_conditions"
                  values={values.health_conditions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={style.form_field}>
                <label>Diet preference</label>
                <MultiSelectBox
                  options={dietpreferencedata}
                  placeHolder="Choose the diet preference"
                  name="diet_preferences"
                  values={values.diet_preferences}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className={style.form_field}>
                    <label>Current weight<span className="image_req">*</span></label>
                    <input
                      name="current_weight"
                      type="text"
                      value={values.current_weight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter current weight"
                      />
                  </div>
                  <div className="error_msg">
                    <ErrorMessage name="current_weight" />
                  </div>
                </div>
                <div className="col-6">
                  <div className={style.form_field}>
                    <label>Targeted weight<span className="image_req">*</span></label>
                    <input
                      name="target_weight"
                      type="text"
                      value={values.target_weight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter targeted weight"
                      />
                  </div>
                  <div className="error_msg">
                    <ErrorMessage name="target_weight" />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.button_wrapper}>
                <button
                  className="input_cancel_btn"
                  type="button"
                  onClick={() =>
                    isCreate ? handleClear(resetForm) :  onClose() 
                  }
                >
                  {isCreate ? "Clear" : "Cancel"}
                </button>
              <button className={style.submit_btn} type="submit">
                {isCreate ? "Create" : "Update"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EnquiryForm;
