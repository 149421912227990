import React, { useEffect, useState } from "react";
import style from "./Layout.module.scss";
import Navbar from "../navbar";
import Container from "../container";
import { useLocation, useParams } from "react-router-dom";
import { http } from "../../utilits/http";

const Layout = ({ children }) => {
  const [data, setData] = useState(""); 

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const getUserData = () => {
    http
      .get("/user-details/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (queryParams.get("token")) {
      localStorage.setItem("token", queryParams.get("token"));
    } else {
      localStorage.removeItem("token");
    }
    getUserData();
  }, []);

  return (
    <div className={style.layout}>
      <div className={style.nav_bar}>
        <Navbar data={data} />
      </div>
      <div className={style.body}>
        <Container>
          <div className={style.content}>{children}</div>
        </Container>
      </div>
    </div>
  );
};

export default Layout;
