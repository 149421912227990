import React , {useState , useEffect} from "react";
import style from './Request-list-table.module.scss';
import MainTable from "../table";
import '../../styles/global.css';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { http } from "../../utilits/http";
import { Link, useParams } from "react-router-dom";
import Search from "../search";
import Heading from "../heading";
import ClickAwayListener from "react-click-away-listener";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../pagination";
import { toast } from "react-toastify";
import {BsDash} from 'react-icons/bs';

const RequestList = () => {
    const [data, setData] = useState({ results: [], count: 0 });
    const [showMenu, setShowMenu] = useState(-1);
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const RequestTableFields = ['sl.no','food name','Quantity (gm)','calories','carbs','fat','proteins','requester','requested by','status','actions'];

    const fetchData = async () => {
        try {
            const response = await http.get(`fitness-food-request/?search=${searchTerm}&page=${currentPage + 1}`);
            setData(response.data);
            // console.log(response.data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, searchTerm]);

    const pageCount = Math.ceil(data.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    

    const filteredData = data.results.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };
    
    const handleRejectRequest = async (id, name) => {
        try {
            const requestData = {
                id: id,
                status: "REJECT",
                name: name, 
            };
    
            await http.patch(`approve-food-request/${id}/`, requestData);
            setData((prevData) => ({
                ...prevData,
                results: prevData.results.map((request) =>
                    request.id === id ? { ...request, approved: "REJECT" } : request
                ),
            }));
            toast.error("Food request is rejected");
        } catch (error) {
            console.log('Error rejecting food:', error);
            toast.error("Failed to reject request");
        }
        setShowMenu(-1);
    };
    

    return(
        <div className={style.request_list}>
            <div>
                <Search onSearch={handleSearch}/>
            </div>
            <div className={style.request_page_head}>
                <Heading name="Food Request"/>
            </div>
            <div className={style.request_list_table}>
            <MainTable headers={RequestTableFields}>
                {filteredData.map((data,index) => {
                const status = data.approved ? "accept" : "pending"; 
                return(
                        <tr className="table_body" key={index}>
                            <td className="table_body_data">{index+1}</td>
                            <td className="table_body_data"><p>{data.name}</p></td>
                            <td className="table_body_data">{data.quantity}</td>
                            <td className="table_body_data">{data.calories}</td>
                            <td className="table_body_data">{data.carbohydrates}</td>
                            <td className="table_body_data">{data.fat}</td> 
                            <td className="table_body_data">{data.proteins}</td>
                            <td className="table_body_data">{data.requester}</td>
                            <td className="table_body_data">{data.requested_by}</td>
                            <td className={`table_body_data ${data.approved === "PENDING" ? 'pending-status' : data.approved === "ACCEPT" ? 'active-status' : 'rejected-status'}`}>
                                {data.approved === "PENDING" ? "Pending" : data.approved === "ACCEPT" ? "Accepted" : "Rejected"}
                            </td>
                            <td className="table_body_action">
                                {data.approved === "PENDING" ? (
                                    <div className="menu_dropdown">
                                        <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                                        {showMenu === index && (
                                            <ClickAwayListener onClickAway={() => setShowMenu(-1)}>
                                                <div className="dropdownContent">
                                                {/* <Link to={`/accept-food-customer/${data.id}/`} className="action_button" onClick={() => handleAcceptRequest(data.id, data.name)}>
                                                            Accept
                                                        </Link> */}
                                                    <div>
                                                        <Link to={`/accept-food-customer/${data.id}/`} className="action_button">
                                                            Accept
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <button className="action_button" type="button" onClick={() => handleRejectRequest(data.id, data.name)}>Reject</button>
                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </div>
                                ) : (
                                    <div className="menu_dropdown"><BsDash /></div>
                                )}
                            </td>
                        </tr>
                    )
                })}       
            </MainTable>      
            </div> 
            {pageCount > 1 && (
                <div>
                    <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                </div>   
            )}
        </div>
    )
}
export default RequestList;