import React , {useState , useEffect} from "react";
import style from './Food-listing-fc.module.scss';
import Container from "../container";
import MainTable from "../table";
import Heading from "../heading";
import Search from "../search";
import { http } from "../../utilits/http";
import Pagination from "../pagination";
import { useLocation, useNavigate } from 'react-router-dom';

const FoodListFc = ( ) => {
    const [data, setData] = useState({ results: [], count: 0 });
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const FoodTableFields= [ "sl.no","food item","calories (per 100 gm)","carbs (gm)","fat (gm)","proteins (gm)" ];

    useEffect(()=>{
        http.get(`food-item/?search=${searchTerm}&page=${currentPage + 1}`).then((res)=>{
            setData(res.data)
            console.log(res.data)
        });
    },[currentPage , searchTerm]);

    const pageCount = Math.ceil(data.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    

    const filteredData = data.results.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    return(
            <div className={style.food_listing_container}>
                <div>
                    <Search onSearch={handleSearch} />
                </div>
                <div>
                    <Heading name= "Food" />
                </div>
                <MainTable headers={FoodTableFields}>
                    {filteredData.map((food,index) => {
                        return(
                            <tr className="table_body" key={index}>
                                <td className="table_body_data">{index+1}</td>
                                <td className="table_body_data"><p>{food.name}</p></td>
                                <td className="table_body_data">{food.calories}</td>
                                <td className="table_body_data">{food.carbohydrates}</td>
                                <td className="table_body_data">{food.fat}</td>
                                <td className="table_body_data">{food.proteins  }</td>
                            </tr>
                        )
                    })}
                </MainTable>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
    )
}
export default FoodListFc;