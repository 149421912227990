import React , { useState , useEffect} from "react";
import style from './Food-listing-and-adding.module.scss';
import FoodTable from "../food-listing-table";
import FoodAddForm from "../food-add-form";
import Container from "../container";
import Heading from "../heading";
import { http } from "../../utilits/http";
import Pagination from "../pagination";
import Search from "../search";
import { useNavigate , useLocation } from "react-router-dom";
import {toast} from 'react-toastify';

const FoodAddAdmin = () => {

    const [addFood, setAddFood] = useState({ results: [], count: 0 });
    const [searchTerm, setSearchTerm] = useState("");
    const [loading , setLoading] =useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const fetchData = async () => {
        try {
            const response = await http.get(`food-item/?search=${searchTerm}&page=${currentPage + 1}`)
            setAddFood(response.data);
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [searchTerm , currentPage]);

    const pageCount = Math.ceil(addFood.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = addFood.results.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await http.delete(`food-item-create/${id.id}/`);
            fetchData();
            setLoading(false);
            toast.success("Food item deleted successfully")
        } catch (error) {
            console.error("Error deleting unit:", error);
            setLoading(false);
            toast.error("Failed to delete food item");
        }
    };

    return(
        <Container>
            <div className={style.food_listin_page_container}>
                <div>
                <Search onSearch={handleSearch} />
                </div>
                <div>
                    <Heading name="food" />
                </div>
                <div className="row">
                    <div className="col-5">
                        <FoodAddForm 
                            onUpdate={fetchData} 
                            showImageField={true}
                        />
                    </div>
                    <div className={`col-7 ${style.food_form_container}`}>
                        <FoodTable addFood={filteredData} onDelete={handleDelete} onUpdate={fetchData} />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default FoodAddAdmin;